.stats-data-section {
  background: white;
  display: flex;
  padding: 30px 20px;
  gap: 40px;
}

@media (min-width: 768px) {
  .stats-data-section {
    padding-bottom: 60px;
    padding-top: 60px;
  }
}

.stats-data-section h2 {
  font-size: 40px;
  line-height: 48px;

  margin-bottom: 8px;
}

.stats-data-section p,
.subtitle {
  font-size: 40px;
  line-height: 48px;
  color: #e73d29;
}

.stats-data-section p span {
  color: #000;
  text-decoration: underline;
}

.data-container {
  margin-top: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.data-subsection {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  padding-bottom: 64px;
}

.data-circle {
  display: flex;
  width: 150px;
  height: 150px;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;

  border-radius: 848px;
  border: 1px solid #000;
  background: var(--primary-500);
  color: #000;
  font-family: Roobert;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 100px;
}

.stats-data-footer {
  color: #000;
  font-family: Roobert;
  font-size: 10px;
  font-style: italic;
  font-weight: 400;
  line-height: 20px; /* 200% */
  text-decoration-line: underline;
}

.stats-image-dekstop {
  display: none;
}

@media (min-width: 768px) {
  .stats-image-dekstop {
    display: block;
  }
}

.stats-image-mobile {
  display: block;
}

@media (min-width: 768px) {
  .stats-image-mobile {
    display: none;
  }
}
