.accordion-title {
  color: var(--primary-600-main);
  text-align: left;
}

.accordion-subtitle {
  text-align: left;
  padding-left: 2px;
}

.accordion-display .MuiAccordionDetails-root {
  padding: 0px;
}

.accordion-display .MuiAccordionSummary-expandIconWrapper {
  transform: rotate(270deg);
}

.accordion-display .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
  transform: rotate(0deg);
}

.accordion-display .MuiAccordionSummary-root {
  background: var(--natural-100) !important;
  border-bottom: 1px solid var(--secondary-600) !important;
}

.accordion-display .MuiAccordion-root {
  box-shadow: none !important;
}

.accordion-display {
  margin: 0px !important;
}

.accordion-display .MuiAccordionSummary-root.Mui-expanded {
  margin: 0px !important;

  background: var(--primary-100) !important;
  border-top: 1px solid var(--secondary-600) !important;
  border-bottom: 1px solid var(--secondary-600) !important;
}

.MuiAccordionDetails-root {
  padding: 0px !important;
}

@media (min-width: 1024px) {
  .MuiAccordionDetails-root {
    padding: 8px 16px 16px !important;
  }
}

.accordion-stack {
  padding: 16px !important;
}

@media (min-width: 1024px) {
  .accordion-stack {
    padding: 24px 40px 40px 32px !important;
  }
}
