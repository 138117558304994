.resources-container {
  text-align: left;

  padding-bottom: 48px;
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 768px) {
  .resources-container {
    padding-top: 20px;
    padding-bottom: 70px;
  }
}

.resource-cards-container {
  align-items: center;

  padding: 0 16px;
}

.resources-container h2 {
  text-align: center;

  font-size: 42px;
  line-height: 50px;
}

.link-container a {
  text-decoration: none;
}

.link-container h4,
.link-container p {
  color: black;
}

.link-container h4 {
  font-size: 20px;
  line-height: 28px;

  margin-bottom: 24px;
}

.link-container p {
  font-size: 14px;
  line-height: 20px;
}

.link-container .card-content-wrapper {
  padding: 20px 20px 0 20px;
}

@media (min-width: 600px) {
  .link-container {
    height: 100%;
  }
}
