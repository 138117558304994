.get-started-container-root {
  background: var(--gradients-mild);

  box-shadow: 0px 8px 5px -5px #00000040;
}

.description-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.get-started-container {
  display: flex;
  padding: 40px 16px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  border-radius: 16px;
  background: #fff;

  margin: 30px 0;

  max-width: 875px;
  margin-left: auto;
  margin-right: auto;
}

.starts-with-you-container {
  text-align: center;

  padding: 0 16px !important;
}

@media (min-width: 1014px) {
  .starts-with-you-container {
    text-align: left;
  }

  .get-started-container-root {
    padding: 62px 0;
  }

  .get-started-container {
    padding: 80px 32px;
  }
}

.starts-with-you-header {
  font-size: 22px;
  line-height: 30px;

  margin-bottom: 10px !important;
}

.starts-with-you-subtext {
  font-size: 14px;
  line-height: 20px;

  margin: 10px 0;
}

.get-started-container .MuiCardActions-root {
  justify-content: center;
}

.get-started-link {
  display: flex;
  align-items: center;
  padding: 10px 25px !important;
  border-radius: 40px;
  background: var(--primary-600-main);
  color: white !important;
  text-decoration: none !important;
}

.get-started-link:hover {
  background: var(--primary-700);
}

.get-started-link-text {
  margin-right: 10px;
}

@media (min-width: 900px) {
  .get-started-container .MuiCardActions-root {
    justify-content: left;
  }
}
