.sticky-button {
  position: sticky;
  top: 0;
  padding: 24px;
  z-index: 2;
  display: flex;
  font-family: "Roobert";
  font-size: 19px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  justify-content: space-between;
  color: #000000;
}

.cta-container {
  text-decoration: none;
  cursor: pointer;
  background: var(--primary-400);
  box-shadow: 0px 8px 5px -5px #00000040;
}

.cta-link-container {
  display: inline-block;
  position: relative;
}

.arrow-art {
  position: absolute;
  right: -60px;
  bottom: -80px;
  overflow: hidden;
}

.circle-art {
  position: absolute;
  top: 0px;
  left: 18px;
}

@media (min-width: 600px) {
  .cta-container .sticky-button {
    justify-content: center;
  }

  .arrow-art {
    top: 30px;
  }

  .circle-art {
    top: 0px;
    left: 14px;
  }
}

@media (min-width: 800px) {
  .circle-art {
    top: 0px;
    left: 35px;
  }
}

@media only screen and (min-width: 1024px) {
  .cta-link-container {
    width: auto;
  }

  .circle-art {
    top: 0px;
    left: 14px;
  }
}
