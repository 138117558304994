.address-lookup-container {
  background: white;
  padding: 10px 20px 20px 20px;

  background: var(--gradients-intense);
  max-width: 1400px;
  margin-left: auto;
  margin-right: auto;
  box-shadow: none !important;
}

@media (min-width: 900px) {
  .address-lookup-container {
    padding: 60px;

    background: white;
  }
}

.address-lookup-container h1 {
  font-size: 32px;
  line-height: 50px;
  margin-bottom: 0;
}

@media (min-width: 376px) {
  .address-lookup-container h1 {
    font-size: 34px;
  }
}

@media (min-width: 600px) {
  .address-lookup-container h1 {
    font-size: 36px;
  }
}

.hero-header {
  text-align: left;
  margin-bottom: 20px;
  max-width: 430px;
}

.hero-image {
  text-align: right;
}
