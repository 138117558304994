@media (min-width: 1014px) {
  .district-card-body {
    width: auto;
  }
}

.district-card-body {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  text-align: left;

  padding: 0px 16px;
}

@media (min-width: 1014px) {
  .district-card-body {
    margin: 0px 24px;
    padding: 0px;
  }
}

.place-address {
  display: flex;
  padding: 16px 16px;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  border: 1px solid #000;
  background: #fff;
  color: #000;
  border-radius: 10px;

  /* Functional/Input */
  font-family: Roobert;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
  flex: 1 0 0;
}

.district-card-body svg {
  transform: rotate(-45deg);
}

.place-address a {
  text-decoration: none;
  padding-left: 10px;
}

.change-address-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 56px 32px 40px 32px;
  background-color: white;
  border: solid 2px #000;
  border-radius: 16px;
  text-align: center;
  width: 80%;
}

.change-address-modal h2 {
  margin: 0 0 30px 0;
}

.pac-container {
  background-color: #fff;
  z-index: 20000;
  position: fixed;
  display: inline-block;
  float: left;
}

.district-card-address-content {
  width: 100%;
  margin-bottom: 48px;
}

.district-card-address-content #lookup{
  align-self: stretch;
  background: #fff;
  border: 1px solid #000;
  border-radius: 10px;
  color: #000;
  display: flex;
  flex: 1 0;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-family: Roobert;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  padding: 0;
}

.district-card-address-content #lookup fieldset{
  border: none;
}

.district-card-address-content #lookup button{
  background: none;
  color: var(--primary-600-main);    
  box-shadow: none;
}

.district-card-address-content #lookup button svg{
  display: none;
}

.place-address-label {
  font-size: 0.8rem;
  padding: 5px 0px;
  margin: 0;
}
