.results-root .sticky-button {
  bottom: 0;
}

.results-root .sticky-button {
  bottom: 0;
}

.results-grid-container {
  width: 100%;
}

.district-accordion {
  margin: 0px;
  background: white;
  box-shadow:
    0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px -1px rgba(0, 0, 0, 0.14),
    0px 1px 3px -3px rgba(0, 0, 0, 0.12);
  padding-bottom: 0px;
}

.district-data-container {
  width: 100%;
}

.district-section-container {
  background: var(--gradients-mellow) !important;
  height: 100%;
  width: 100%;
  margin-bottom: 16px;
  box-shadow: 0px -8px 11px -5px #000000;
}

.district-section-titles {
  font-family: Roobert;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.district-section-district-info-container {
  padding: 20px;
  border-bottom: 1px solid var(--secondary-600) !important;
}

.district-section-district-info {
  text-align: left;
  margin-bottom: 15px;
}

.district-container {
  margin-bottom: 32px;
  padding: 10px;
}

.district-container:last-child {
  border-bottom: none;
}

.district-titles .active {
  font-weight: bold;
}

.district-card-extra-information {
  width: 100%;
  padding: 0 24px;
}

.district-titles-container {
  display: flex;
  padding: 0px 16px;
  margin-bottom: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;

  background: none;
  border: none;
  text-align: left;
}

.district-title {
  font-size: 22px;
  line-height: 30px;
  margin: 0px;
  text-align: left;
}

@media (min-width: 1024px) {
  .district-title {
    font-size: 32px;
    line-height: 40px;
    margin: 0px;
    text-align: left;
  }
}

.district-website-link-container {
  padding: 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

@media (min-width: 1024px) {
  .district-website-link-container {
    margin-bottom: 16px;
  }
}

.district-website-link-container a {
  text-decoration: none;
  padding: 6px 14px;
}

@media (min-width: 1024px) {
  .district-website-link-container {
    padding-top: 20px;
    margin-bottom: 0px;
  }

  .district-website-link-container a {
    padding: 12px 28px;
  }
}

.district-website-link {
  border-radius: 10px;
  display: inline-flex;
  padding: 12px 28px;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
  text-transform: none;
  border: 1px solid var(--primary-600-main);
  color: var(--primary-600-main);
  text-decoration: inherit;
}

.district-website-link:hover {
  text-decoration: none;
  background-color: rgba(20, 87, 88, 0.04);
  border: 1px solid rgb(20, 87, 88);
}

@media (min-width: 1024px) {
  .MuiAccordion-root::before {
    display: none;
  }
}

.Mui-expanded .district-container-summary {
  align-items: flex-start !important;
}

.district-container-summary-icon {
  padding-bottom: 20px;
  padding-top: 20px;
}

.district-container-summary-address {
  margin: 0px;
  text-align: left;
  font-size: 14px;
}

.district-card-header {
  text-align: left;
  padding-left: 16px;
  margin-bottom: 16px;
}

@media (min-width: 1024px) {
  .district-card-header {
    padding: 0;
  }
}

.district-card-header-result-count {
  display: inline-block;
  background: black;
  color: white;
  border-radius: 5px;
  padding: 2px 8px;
  margin-bottom: 10px;
  font-size: 14px;
}

.district-card-header-label {
  display: block;
  font-size: 14px;
}

.district-superintendent-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.district-superintendent-container svg {
  color: var(--primary-600-main);
  width: 25px;
  height: 25px;
}

@media (min-width: 1024px) {
  .district-superintendent-containerboard-member svg {
    width: 40px;
    height: 40px;
  }
}
